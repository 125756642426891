.form-input {
    position: relative;
    padding-top: 2px;
}

.slash {
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
    /* Start with the slash hidden */
}

.form-input input[type="text"],
.form-input input[type="email"],
.form-input input[type="password"] {
        /* Apply the same styles to the password input */
    padding: 12px;
    font-size: 1rem;
    border: 2px solid #e2e8f0 !important;
    border-radius: 0.375rem;
    /* This adds rounded corners */
    width: 100%;
    transition: border-color 0.2s;
}

.form-input input[type="text"]:focus,
.form-input input[type="email"]:focus,
.form-input input[type="password"]:focus {
    /* Apply focus style to password input */
    outline: none;
    border-color: #4a5568 !important;
    /* Corrected the typo in the color code */
}

.form-input label {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    color: #a1a1a1 !important;
    background-color: white !important;
    transition: all 0.2s;
    padding: 0 4px;
}

.form-input input:focus+label,
.form-input input:not(:placeholder-shown)+label {
    top: -9px;
    /* Adjust this value as needed to align with the box line */
    transform: translateY(0);
    left: 10px;
    color: #4a5568 !important;
    font-size: 0.75rem;
    font-weight: 600;
}

.btn {
    background-color: #10b981 !important;
    color: white !important;
    padding: 12px 20px;
    border: none;
    width: 100%;
    margin-top: 8px;
    font-size: 1rem;
    border-radius: 0.375rem;
    transition: background-color 0.2s ease;
    /* Adds a smooth transition effect */
}

.btn:hover {
    background-color: #0dad77 !important;
}

.login-link {
    text-decoration: none !important;
    /* Removes the underline */
    transition: color 0.2s;
    /* Smooth color transition */
}

/* Hover effect */
.login-link:hover {
    color: #0dad77;
    /* Example of a darker green; adjust as needed */
}

.sonner {
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
}
 .form-input {
     position: relative;
     padding-top: 5px;
 }

 .slash {
     transition: opacity 0.3s ease-in-out;
     opacity: 0;
     /* Start with the slash hidden */
 }

 .form-input input[type="email"],
 .form-input input[type="password"] {
     /* Apply the same styles to the password input */
     padding: 12px;
     font-size: 1rem;
     border: 2px solid #e2e8f0;
     border-radius: 0.375rem;
     /* This adds rounded corners */
     width: 100%;
     transition: border-color 0.2s;
 }

 .form-input input[type="email"]:focus,
 .form-input input[type="password"]:focus {
     /* Apply focus style to password input */
     outline: none;
     border-color: #4a5568;
     /* Corrected the typo in the color code */
 }

 .form-input label {
     position: absolute;
     top: 50%;
     left: 12px;
     transform: translateY(-50%);
     color: #a1a1a1;
     background-color: white;
     transition: all 0.2s;
     padding: 0 4px;
 }

 .form-input input:focus+label,
 .form-input input:not(:placeholder-shown)+label {
     top: -9px;
     /* Adjust this value as needed to align with the box line */
     transform: translateY(0);
     left: 10px;
     color: #4a5568;
     font-size: 0.75rem;
     font-weight: 600;
 }

 /* .continuebtn {
    background-color: #10b981;
    color: rgb(252, 250, 250);
    padding: 12px 20px;
    border: 2px solid #10b981;
    width: 100%;
    margin-top: 8px;
    font-size: 1rem;
    border-radius: 0.375rem;
    transition: transform 0.3s ease;
 }

 .continuebtn:hover {
     transform: scale(1.02);
 } */

 .continuebtn {
        background-color: #262626 !important;
        color: rgb(255, 255, 255);
        padding: 12px 20px;
        border: none;
        width: 100%;
        margin-top: 8px;
        font-size: 1rem;
        border-radius: 0.375rem;
        transition: background-color 0.2s ease;
        /* Adds a smooth transition effect */
    }

 /* Hover state */
 .continuebtn:hover {
     background-color: #282828 !important;
 }

 .btn-google {
     background-color: white;
     color: #10b981;
     padding: 12px 20px;
     border: 2px solid #10b981;
     width: 100%;
     margin-top: 8px;
     font-size: 1rem;
     border-radius: 0.375rem;
 }

 /* Targeting the link directly */
 .signup-link {
     text-decoration: none !important;
     /* Removes the underline */
     transition: color 0.2s;
     /* Smooth color transition */
 }

 /* Hover effect */
 .signup-link:hover {
     color: #2c2c2c;
     /* Example of a darker green; adjust as needed */
 }